<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="支付" width="700px" @closed="form = null">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":" v-loading="loading">
          <el-form-item label="采购单号">{{form.relationFormCode}}</el-form-item>
          <el-form-item prop="payMoney" label="支付金额">
            <div class="h c">
              <price-input v-model="form.payMoney" :min="0" :max="(remainMoney - form.accountMoney) / 100" style="width: 160px;" />
              <div class="padding-0-10">还剩余{{$price(remainMoney - form.accountMoney)}}需要支付</div>
            </div>
          </el-form-item>
          <el-form-item prop="accountMoney" label="使用余额">
            <div class="h c">
              <price-input v-model="form.accountMoney" :min="0" :max="remainMoney / 100" style="width: 160px;" />
              <div class="padding-0-10">可使用余额：{{$price(maxRemain)}}</div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="使用返利" prop="payRebate">
            <div class="h c" v-if="form.relationFormCode">
              <price-input v-model="form.payRebate" :min="0" :max="maxRebate" style="width: 160px;" />
              <div class="padding-0-10">最高可使用{{$price(maxRebate * 100)}}的返利</div>
            </div>
          </el-form-item>-->
          <el-form-item prop="payerAccount" label="支付账户">
            <div class="h">
              <el-input v-model="form.payerAccount" maxlength="80" placeholder="请选择或填写支付方的账户信息" class="flex" />
              <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :popper-width="800" :show-index="false" @change="handlePayerAccountChange" style="margin-left: 10px;">
                <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                <el-table-column prop="accountCode" label="账号" />
                <el-table-column prop="accountName" label="账户" />
                <el-table-column prop="bankName" label="银行名称" />
              </datagrid-picker>
            </div>
          </el-form-item>
          <el-form-item prop="payeeAccount" label="收款账户">
            <div class="h">
              <el-input v-model="form.payeeAccount" maxlength="80" placeholder="请选择或填写收款方的账户信息" class="flex" />
              <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/account" :params="{entId:form.entId, sort: 'createAt,desc'}" :popper-width="800" :show-index="false" @change="handlePayeeAccountChange" style="margin-left: 10px;">
                <el-table-column label="账户类型" :formatter="r => {return formTypes[r.accountType];}" />
                <el-table-column prop="accountCode" label="账号" />
                <el-table-column prop="accountName" label="账户" />
                <el-table-column prop="bankName" label="银行名称" />
              </datagrid-picker>
            </div>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="200" :rows="3" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button type="primary" size="mini" :loading="saving" @click="doSubmit">提交</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="68px" label-position="right" label-suffix=":">
          <form-info-item label="采购单号">{{form.relationFormCode}}</form-info-item>
          <form-info-item label="支付日期">{{new Date(form.payTime).format('yyyy/MM/dd')}}</form-info-item>
          <form-info-item label="支付金额">{{$price(form.payMoney)}}</form-info-item>
          <form-info-item label="手续费" v-if="receipt">
            <b>{{$price(form.fee)}}</b>
            <span class="fs-mini fc-g" v-if="form.fee > 0">
              <br />
              经销商承担{{$price(form.payFee)}}
              <br />
              供应商承担{{$price(form.fee - form.payFee)}}
            </span>
          </form-info-item>
          <form-info-item label="手续费" v-else>
            <b>{{$price(form.payFee)}}</b>
          </form-info-item>

          <form-info-item label="使用余额">{{$price(form.accountMoney)}}</form-info-item>
          <form-info-item label="到账日期" v-if="form.status === 1">{{new Date(form.checkinTime).format('yyyy/MM/dd')}}</form-info-item>
          <template v-if="form.payType === 1">
            <form-info-item label="支付账户">{{form.payerAccount}}</form-info-item>
            <form-info-item label="收款账户">{{form.payeeAccount}}</form-info-item>
          </template>
          <form-info-item label="备注">{{form.info}}</form-info-item>
          <el-divider />
          <form-info-item label="当前状态">
            <dot same :type="status[form.payType][form.status].type">{{status[form.payType][form.status].label}}</dot>
          </form-info-item>
          <form-info-item label="审批意见" v-if="form.checkInfo">{{form.checkInfo}}</form-info-item>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>
      
      
<script>
import { add, edit } from "@/api/payForm";
import { getBySupplier } from "@/api/capitalPool";
// import { getMaxRebateByPurchaseOrder } from "@/api/rebate";

export default {
  props: {
    receipt: Boolean //是否为收款信息, 否为付款信息
  },
  data() {
    return {
      saving: false,
      loading: false,
      dialog: false,
      form: null,
      maxRebate: 0,
      remainMoney: 0,
      maxRemain: 0,
      formTypes: ["银行卡", "支付宝", "微信"],
      status: [
        [
          {
            type: "info",
            label: "待支付"
          },
          {
            type: "success",
            label: "已支付"
          },
          {
            type: "danger",
            label: "支付失败"
          }
        ],
        [
          {
            type: "info",
            label: "待审批"
          },
          {
            type: "success",
            label: "已通过"
          },
          {
            type: "danger",
            label: "未通过"
          }
        ]
      ],
      rules: {
        payTime: [{ required: true, message: "请选择收款日期" }],
        payMoney: [{ required: true, message: "请填写收款金额" }],
        payerAccount: [{ required: true, message: "请填写支付方的账户" }],
        payeeAccount: [{ required: true, message: "请填写收款方的账户" }]
      }
    };
  },
  computed: {
    editable() {
      return this.form && !this.form.id;
    }
  },
  methods: {
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.$confirm(
            "支付信息提交后将不能修改，请确认所填信息无误。<br />确定要进行提交吗？",
            "操作确认",
            {
              type: "warning",
              dangerouslyUseHTMLString: true
            }
          ).then(_ => {
            this.saving = true;
            (this.form.id ? edit : add)(this.form)
              .then(res => {
                this.$notify({
                  title: "添加成功",
                  type: "success",
                  duration: 2500
                });
                this.$emit("paied");
                this.dialog = false;
              })
              .finally(_ => {
                this.saving = false;
              });
          });
        });
    },
    getMaxRemain() {
      if (this.form && this.form.entId) {
        getBySupplier(this.form.entId).then(res => {
          let max = res.canUseBalance;
          if (max < 0) max = 0;
          if (max > this.remainMoney) max = this.remainMoney;
          this.maxRemain = max;
        });
      }
    },
    resetForm(form, order, payMoney) {
      if (form) {
        this.form = form;
        this.dialog = true;
      } else if (order) {
        this.remainMoney = payMoney || 0;
        if (this.remainMoney > 0) {
          this.form = {
            code: "",
            payType: 1,
            payerAccount: "",
            entId: order.sellerId,
            payTime: new Date().getTime(),
            payMoney: this.remainMoney,
            accountMoney: 0,
            payRebate: 0,
            payeeAccount: "",
            relationFormType: 0,
            relationFormId: order.id,
            relationFormCode: order.formCode,
            info: "",
            status: 0
          };
          this.getMaxRemain();
          //this.loadMaxRebate(order.id);
          this.dialog = true;
        }
      }
    },
    // loadMaxRebate(id, init) {
    //   this.loading = true;
    //   getMaxRebateByPurchaseOrder(id).then(res => {
    //     this.remainMoney = res.orderTotleMoney - res.usedRebate - res.useMoney;
    //     if (this.remainMoney < 0) this.remainMoney = 0;
    //     let maxRebate = res.maxCanUseRebate || 0;
    //     if (!init) {
    //       if (maxRebate > this.remainMoney) maxRebate = this.remainMoney;
    //       this.form.payRebate = maxRebate;
    //       this.form.payMoney = this.remainMoney - maxRebate;
    //     }
    //     this.maxRebate = maxRebate / 100;
    //   }).finally(_=> {
    //     this.loading = false;
    //   });
    // },
    handlePayerAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payerAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payerAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    },
    handlePayeeAccountChange(newVal, oldVal, row) {
      if (row.accountType === 0) {
        this.form.payeeAccount = `${row.bankName} (${row.accountName}, ${row.accountCode})`;
      } else {
        this.form.payeeAccount = `${this.formTypes[row.accountType]} (${
          row.accountCode
        })`;
      }
    }
  }
};
</script>